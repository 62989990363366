function PopupCenter( url, title, w, h ) {
  var docEl = document.documentElement;
  
  // Fixes dual-screen position                         Most browsers      Firefox
  var dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX;
  var dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY;

  var width  = window.innerWidth ? window.innerWidth : docEl.clientWidth ? docEl.clientWidth : screen.width;
  var height = window.innerHeight ? window.innerHeight : docEl.clientHeight ? docEl.clientHeight : screen.height;

  var left = ( ( width / 2 ) - ( w / 2 ) ) + dualScreenLeft;
  var top = ( ( height / 2 ) - ( h / 2 ) ) + dualScreenTop;
  var newWindow = window.open( 
    url, title, 'scrollbars=yes, width=' + w + ', height=' + h + ', top=' + top + ', left=' + left 
  );

  // Puts focus on the newWindow
  if( window.focus ) {
    newWindow.focus();
  }
}
